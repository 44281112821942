import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router";
import Loading from "../../components/loading";
import MetricTable from "./MetricTable";

const ChooseMetricTables: React.FC = () => {
  const { id } = useParams();
  const { data, loading: loadingGoogleSheets } = useQuery(
    LIST_GOOGLE_SHEET_TABLES,
    {
      variables: {
        input: {
          dataSourceId: id
        }
      }
    }
  );

  const { loading: loadingMetricTables, data: metricTablesData } = useQuery(
    METRIC_TABLES,
    {
      variables: { dataSourceId: id }
    }
  );

  if (loadingGoogleSheets || loadingMetricTables) return <Loading />;
  const tables = data.listGoogleSheetTables;
  const metricTables = metricTablesData.metricTables;

  return (
    <>
      <p className="my-3 text-gray-900">Select tables to sync</p>
      <table className="w-full table-fixed text-sm">
        <thead className="font-semibold text-left pb-2">
          <tr>
            <th className="w-4 text-center"></th>
            <th className="w-32">Table name</th>
            <th className="w-24">Labels</th>
            <th className="w-24">Values</th>
          </tr>
        </thead>
        <tbody>
          {tables.map((table: any) => (
            <MetricTable
              metricTable={metricTables.find(
                (metricTable: any) => metricTable.name === table.title
              )}
              key={table.title}
              table={table}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ChooseMetricTables;

const METRIC_TABLES = gql`
  query metricTables($dataSourceId: ID!) {
    metricTables(dataSourceId: $dataSourceId) {
      id
      name
      labelsColumn
      valuesColumn
    }
  }
`;

const LIST_GOOGLE_SHEET_TABLES = gql`
  query listGoogleSheetTables($input: ListGoogleSheetTablesInput!) {
    listGoogleSheetTables(input: $input) {
      index
      title
    }
  }
`;
