import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Notify from "../../components/notify";

interface Props extends React.FC {
  notify?: {
    hide: () => void;
  };
}

const DisconnectDataSource: Props = () => {
  const { id } = useParams();
  const history = useHistory();
  const [disconnect, { loading: disconnecting }] = useMutation(DISCONNECT, {
    onCompleted: () => {
      DisconnectDataSource.notify!.hide();
      history.push("/settings/data-sources");
      Notify.success("Disconnected successfully");
    },
    onError: error => {
      DisconnectDataSource.notify!.hide();
      Notify.graphQLError(error);
    }
  });

  return (
    <button
      disabled={disconnecting}
      onClick={() => {
        DisconnectDataSource.notify = Notify.loading("Disconnecting...");
        disconnect({
          variables: {
            id
          }
        });
      }}
      className="text-red-500 px-3"
    >
      {disconnecting ? "Disconnecting..." : "Disconnect"}
    </button>
  );
};

export default DisconnectDataSource;

const DISCONNECT = gql`
  mutation disconnectDataSource($id: ID!) {
    disconnectDataSource(id: $id) {
      id
      name
      status
      connectedAccount {
        id
        name
      }
    }
  }
`;
