import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { Input, SubmitBtn } from "../../components/form-inputs";
import GraphQLError from "../../components/graphql-error";
import Head from "../../components/head";
import Notify from "../../components/notify";
import PageHeader from "../../components/page-header";
import UserContext from "../../contexts/user";
import { useForm } from "../../hooks/use-form";

const UPDATE_USER = gql`
  mutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      email
    }
  }
`;

const AccountSettings = () => {
  const { user } = useContext(UserContext);
  const [login, { loading, error }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      Notify.saved();
    }
  });
  const { fieldProps, currentValues } = useForm({
    initialValues: {
      id: user!.id,
      email: user!.email,
      name: user!.name,
      projectName: user!.projects![0].name
    }
  });

  return (
    <>
      <Head title="Account settings" />
      <PageHeader>Account settings</PageHeader>
      <form
        onSubmit={e => {
          e.preventDefault();
          login({
            variables: {
              input: currentValues
            }
          });
        }}
        className="md:w-1/3"
      >
        <GraphQLError error={error} />
        <Input
          label="Email"
          inputProps={{
            type: "email",
            ...fieldProps("email")
          }}
        />
        <Input
          label="Name"
          inputProps={{
            type: "text",
            ...fieldProps("name")
          }}
        />
        <Input
          label="Company name"
          inputProps={{
            type: "text",
            ...fieldProps("projectName")
          }}
        />
        <SubmitBtn loading={loading}>Save</SubmitBtn>
      </form>
    </>
  );
};

export default AccountSettings;
