import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router";
import Loading from "../../components/loading";
import Notify from "../../components/notify";

export interface GoogleSheet {
  id: string;
  name: string;
}

interface Props {
  sheet: GoogleSheet;
}

const ChooseSpreadsheet: React.FC<Props> = ({ sheet }) => {
  const { id } = useParams();
  const [update, { loading: updating }] = useMutation(UPDATE, {
    refetchQueries: [{ query: DATASOURCE, variables: { id } }],
    onError: error => {
      Notify.graphQLError(error);
    }
  });

  if (updating) return <Loading />;

  return (
    <button
      onClick={() => {
        update({
          variables: {
            input: {
              id,
              connectedAccount: {
                id: sheet.id,
                name: sheet.name
              }
            }
          }
        });
      }}
      className="block text-sm text-brand-blue opacity-75 hover:opacity-100"
    >
      {sheet.name}
    </button>
  );
};

export default ChooseSpreadsheet;

const UPDATE = gql`
  mutation updateDataSourceAccount($input: UpdateDataSourceAccountInput!) {
    updateDataSourceAccount(input: $input) {
      id
      status
      connectedAccount {
        id
        name
      }
    }
  }
`;

const DATASOURCE = gql`
  query dataSource($id: ID!) {
    dataSource(id: $id) {
      id
      status
      connectedAccount {
        id
        name
      }
    }
  }
`;
