import React from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "./CustomToolTip";
import SmallNumber, { Props } from "./SmallNumber";

const data = [
  {
    name: "Visits",
    amt: 2400
  },
  {
    name: "Signups",
    amt: 292
  },
  {
    name: "Paid",
    amt: 54
  }
];

const TinyBarChart: React.FC<Props> = ({ value }) => {
  return (
    <>
      <SmallNumber value={value} />
      <BarChart
        width={260}
        height={130}
        data={data}
        margin={{
          left: -18,
          right: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={CustomTooltip} />
        <Bar dataKey="amt" fill="#377DFF" />
      </BarChart>
    </>
  );
};

export default TinyBarChart;
