import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import { DASHBOARD } from "../../pages/dashboards/Manage";
import Form from "./_Form";

const EditDashboardMetric = () => {
  const { dashboardId, dashboardMetricId } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery(DASHBOARD, {
    variables: { id: dashboardId }
  });
  const onCompleted = () => history.push(`/settings/dashboards/${dashboardId}`);
  const [update, { loading: updating, error }] = useMutation(UPDATE_METRIC, {
    onCompleted
  });
  const [destroy, { loading: destroying }] = useMutation(DELETE_METRIC, {
    refetchQueries: [{ query: DASHBOARD, variables: { id: dashboardId } }],
    onCompleted
  });

  if (loading) return <Loading />;
  const { dashboard } = data;
  const dashboardMetric = dashboard.dashboardMetrics.find(
    (metric: any) => metric.id === dashboardMetricId
  );

  return (
    <>
      <Form
        title="Edit metric"
        submitLabel="Update"
        error={error}
        initialValues={{
          name: dashboardMetric.name,
          metricId: dashboardMetric.metricId,
          type: dashboardMetric.type,
          dateRange: dashboardMetric.dateRange
        }}
        submitting={updating}
        onSubmit={({ currentValues }) => {
          update({
            variables: {
              input: {
                id: dashboardMetric.id,
                ...currentValues
              }
            }
          });
        }}
        deleting={destroying}
        onDelete={() => {
          destroy({
            variables: {
              input: {
                id: dashboardMetric.id
              }
            }
          });
        }}
      />
    </>
  );
};

export default EditDashboardMetric;

const UPDATE_METRIC = gql`
  mutation updateDashboardMetric($input: UpdateDashboardMetricInput!) {
    updateDashboardMetric(input: $input) {
      id
      name
      type
      position
      dateRange
      metricId
    }
  }
`;

const DELETE_METRIC = gql`
  mutation deleteDashboardMetric($input: DeleteInput!) {
    deleteDashboardMetric(input: $input) {
      id
    }
  }
`;
