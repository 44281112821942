import { ApolloError } from "apollo-client";
import cogoToast from "cogo-toast";

export const graphQLError = (error: ApolloError) => {
  const errors = error.graphQLErrors[0].extensions!.exception.errors;
  const messages = Object.values(errors);
  messages.forEach(message => {
    cogoToast.error(String(message), {
      position: "bottom-right"
    });
  });

  return true;
};

export const loading = (message: string = "Loading...") =>
  cogoToast.loading(message, {
    position: "bottom-right"
  });

export const success = (message: string = "Success") =>
  cogoToast.success(message, {
    position: "bottom-right"
  });

export const saved = (message: string = "Saved successfully") =>
  cogoToast.success(message, {
    position: "bottom-right"
  });

export default {
  saved,
  success,
  loading,
  graphQLError
};
