import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import { DASHBOARD } from "../dashboards/Manage";
import Form from "./_Form";

const NewDashboardMetric = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery(DASHBOARD, {
    variables: { id }
  });
  const [create, { loading: creating, error }] = useMutation(CREATE_METRIC, {
    refetchQueries: [
      {
        query: DASHBOARD,
        variables: {
          id
        }
      }
    ],
    onCompleted: () => {
      history.push(`/settings/dashboards/${id}`);
    }
  });

  if (loading) return <Loading />;
  const position = data.dashboard.dashboardMetrics.length;

  return (
    <Form
      title="Add a new metric"
      submitLabel="Create"
      error={error}
      initialValues={{
        name: "",
        metricId: "",
        type: "number",
        dateRange: "total"
      }}
      submitting={creating}
      onSubmit={({ currentValues }) => {
        const { name, metricId, dateRange } = currentValues;
        create({
          variables: {
            input: {
              position,
              name,
              metricId,
              dateRange,
              dashboardId: id
            }
          }
        });
      }}
    />
  );
};

export default NewDashboardMetric;

const CREATE_METRIC = gql`
  mutation createDashboardMetric($input: CreateDashboardMetricInput!) {
    createDashboardMetric(input: $input) {
      id
      name
      type
      position
      dateRange
    }
  }
`;
