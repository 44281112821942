import React from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useAuthed } from "../hooks/use-authed";
import Head from "../components/head";
import { Input, SubmitBtn } from "../components/form-inputs";
import GraphQLError from "../components/graphql-error";
import { useForm } from "../hooks/use-form";

const LOGIN = gql`
  mutation($input: LoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        name
        email
        projects {
          id
          name
          dashboards {
            id
          }
        }
      }
    }
  }
`;

const Login = () => {
  const [onAuthed] = useAuthed();
  const [login, { loading, error }] = useMutation(LOGIN, {
    onCompleted: data => {
      onAuthed(data.login);
    }
  });
  const { fieldProps, currentValues } = useForm({
    initialValues: {
      email: "",
      password: ""
    }
  });

  return (
    <>
      <Head title="Login" />
      <p className="text-gray-600">Log in with an existing account</p>
      <form
        onSubmit={e => {
          e.preventDefault();
          login({
            variables: {
              input: currentValues
            }
          });
        }}
        className="py-8"
      >
        <GraphQLError error={error} />
        <Input
          label="Email"
          inputProps={{
            placeholder: "john@doe.com",
            type: "email",
            ...fieldProps("email")
          }}
        />
        <Input
          label="Password"
          inputProps={{
            placeholder: "Password",
            type: "password",
            ...fieldProps("password")
          }}
        />
        <SubmitBtn loading={loading}>Log in</SubmitBtn>
        <Link
          className="ml-3 text-md inline-block py-2 px-4 rounded text-brand-blue"
          href="/signup"
          to="/signup"
        >
          Need an account?
        </Link>
      </form>
    </>
  );
};

export default Login;
