import { CaretDownFill } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import React from "react";
import Footer from "../components/footer";
import LoggedIn from "../layouts/LoggedIn";
AntdIcon.add(CaretDownFill);

const Dashboard: React.FC = ({ children }) => {
  return (
    <div className="w-11/12 py-6 mx-auto">
      <div className="min-h-screen">{children}</div>
      <Footer />
    </div>
  );
};

const DashboardLoggedIn: React.FC = props => (
  <LoggedIn>
    <Dashboard {...props} />
  </LoggedIn>
);

export default DashboardLoggedIn;
