import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import React, { useContext } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import Head from "../../components/head";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import PageHeader from "../../components/page-header";
import ReorderButton from "../../components/reorder-button";
import SettingButton from "../../components/setting-button";
import SortableList from "../../containers/SortableList";
import UserContext from "../../contexts/user";
import New from "../../pages/dashboards/New";

const Dashboards = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { loading, data } = useQuery(DASHBOARDS, {
    variables: { projectId: user!.projects[0].id }
  });

  if (loading) return <Loading />;
  const projectDashboards = orderBy(data.projectDashboards, "position");

  return (
    <>
      <Head title="Dashboards" />
      <PageHeader>Dashboards</PageHeader>
      <Link
        to="/settings/dashboards/new"
        className="inline-block mb-5 bg-brand-blue text-white py-1 px-2 rounded"
      >
        + Add a new dashboard
      </Link>
      <SortableList
        useDragHandle
        updateMutation={UPDATE_DASHBOARD_POSITION}
        data={projectDashboards}
        renderItem={({ item }) => {
          return (
            <div className="mr-3 mb-3 rounded p-4 border border-gray-300 w-96">
              <div className="flex justify-between">
                <div>
                  <div className="text-xl">{item.name}</div>
                  <div className="text-gray-500">{item.description}</div>
                </div>
                <SortHandle />
              </div>
              <Link
                className="block mt-3 text-brand-blue text-sm flex items-center"
                to={`/settings/dashboards/${item.id}`}
              >
                <SettingButton />
                <div className="ml-2">Manage</div>
              </Link>
            </div>
          );
        }}
      />
      <Switch>
        <Route
          path="/settings/dashboards/new"
          render={props => (
            <Modal
              size="max-w-xl"
              isOpen={true}
              onHide={() => {
                history.push("/settings/dashboards");
              }}
            >
              <New />
            </Modal>
          )}
        />
      </Switch>
    </>
  );
};

const SortHandle = SortableHandle(() => (
  <button className="mr-2 block text-gray-500">
    <ReorderButton />
  </button>
));

const UPDATE_DASHBOARD_POSITION = gql`
  mutation updateDashboard($input: UpdateDashboardInput!) {
    updateDashboard(input: $input) {
      id
      position
    }
  }
`;

export const DASHBOARDS = gql`
  query projectDashboards($projectId: ID!) {
    projectDashboards(projectId: $projectId) {
      id
      position
      name
      description
    }
  }
`;

export default Dashboards;
