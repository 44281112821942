import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import Loading from "../../components/loading";
import ChooseSpreadsheet, { GoogleSheet } from "./ChooseSpreadsheet";
interface ListSpreadSheetsProps {
  dataSourceId: string;
  name: string;
}

const ListSpreadsheets: React.FC<ListSpreadSheetsProps> = ({
  dataSourceId,
  name
}) => {
  const { loading, data } = useQuery(FIND_GOOGLE_SHEETS, {
    variables: {
      input: {
        dataSourceId,
        name
      }
    }
  });

  if (loading)
    return (
      <div className="mt-3">
        <Loading />
      </div>
    );

  const sheets = data.findGoogleSheets;

  return (
    <div className="mt-3">
      {sheets.length === 0 && (
        <div className="text-sm">No spreadsheets found with that name</div>
      )}
      {sheets.length > 0 && (
        <p className="text-sm mb-2">
          We found the spreadsheets below. Click one to use it for this data
          source.
        </p>
      )}
      {sheets.map((sheet: GoogleSheet) => (
        <ChooseSpreadsheet key={sheet.id} sheet={sheet} />
      ))}
    </div>
  );
};

export default ListSpreadsheets;

const FIND_GOOGLE_SHEETS = gql`
  query findGoogleSheets($input: FindGoogleSheetsInput!) {
    findGoogleSheets(input: $input) {
      id
      name
    }
  }
`;
