import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Link, Route, Switch, useHistory, useParams } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import ReorderButton from "../../components/reorder-button";
import SettingButton from "../../components/setting-button";
import SortableList from "../../containers/SortableList";
import { DASHBOARD } from "../../pages/dashboards/Manage";
import Edit from "./Edit";
import New from "./New";

const DashboardMetricsList = () => {
  const history = useHistory();
  const { id } = useParams();
  const { loading, data } = useQuery(DASHBOARD, {
    variables: { id }
  });

  if (loading) return <Loading />;
  const { dashboard } = data;

  return (
    <>
      <Link
        to={`/settings/dashboards/${dashboard.id}/metrics/new`}
        className="inline-block mb-5 bg-brand-blue text-white py-1 px-2 rounded"
      >
        + Add a new metric
      </Link>
      <SortableList
        useDragHandle
        updateMutation={UPDATE_METRIC_POSITION}
        data={dashboard.dashboardMetrics}
        renderItem={({ item }) => {
          return (
            <div className="text-gray-800 text-sm border bg-white border-gray-300 rounded p-3 mb-2 mr-2 w-64">
              <div className="flex justify-between">
                <div className="flex">
                  <div>{item.name}</div>
                </div>
                <div className="flex items-start">
                  <SortHandle />
                  <Link
                    to={`/settings/dashboards/${dashboard.id}/metrics/${item.id}/edit`}
                    className="inline-block text-brand-blue"
                  >
                    <SettingButton />
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      />
      <Switch>
        <Route
          exact
          path="/settings/dashboards/:dashboardId/metrics/:dashboardMetricId/edit"
          render={props => (
            <Modal
              size="max-w-xl"
              isOpen={true}
              onHide={() => {
                history.push(`/settings/dashboards/${id}`);
              }}
            >
              <Edit />
            </Modal>
          )}
        />
        <Route
          exact
          path="/settings/dashboards/:id/metrics/new"
          render={props => (
            <Modal
              size="max-w-xl"
              isOpen={true}
              onHide={() => {
                history.push(`/settings/dashboards/${id}`);
              }}
            >
              <New />
            </Modal>
          )}
        />
      </Switch>
    </>
  );
};

const SortHandle = SortableHandle(() => (
  <button className="mr-2 block text-gray-500">
    <ReorderButton />
  </button>
));

const UPDATE_METRIC_POSITION = gql`
  mutation updateDashboardMetric($input: UpdateDashboardMetricInput!) {
    updateDashboardMetric(input: $input) {
      id
      position
    }
  }
`;

export default DashboardMetricsList;
