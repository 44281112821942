import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router";
import Loading from "../../components/loading";
import ChooseAccount from "./ChooseAccount";
import ChooseMetrics from "./ChooseMetrics";
import Disconnect from "./Disconnect";
import Done from "./Done";
import Header from "./Header";

const EditDataSource: React.FC = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(DATASOURCE, {
    variables: { id }
  });

  if (loading) return <Loading />;
  const dataSource = data.dataSource;

  return (
    <div>
      <Header />
      <ChooseAccount />
      {dataSource.status === "connected" && <ChooseMetrics />}
      <div className="mt-5 flex justify-between items-center">
        <Done />
        <Disconnect />
      </div>
    </div>
  );
};

export default EditDataSource;

const DATASOURCE = gql`
  query dataSource($id: ID!) {
    dataSource(id: $id) {
      id
      name
      status
      connectedAccount {
        id
        name
      }
      connectedAccounts {
        id
        name
      }
      metrics {
        id
        name
        status
      }
    }
  }
`;
