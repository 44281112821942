import React from "react";

interface Props {
  dataSource: {
    connectedAccount: {
      id: string;
      name: string;
    };
  };
}
const ConnectedSpreadsheet: React.FC<Props> = ({ dataSource }) => (
  <div>
    <span className="text-gray-600">Connected to spreadsheet:</span>{" "}
    <a
      className="text-brand-blue"
      target="_new"
      href={`https://docs.google.com/spreadsheets/d/${dataSource.connectedAccount.id}`}
    >
      {dataSource.connectedAccount.name}
    </a>
  </div>
);

export default ConnectedSpreadsheet;
