import React from "react";

export interface Dashboard {
  id: string;
  name: string;
  description: string;
}
export interface Project {
  id: string;
  name: string;
  dashboards: Dashboard[];
}

export interface UserState {
  id?: string;
  email?: string;
  name?: string;
  projects: Project[];
}

export interface InitialState {
  user?: UserState | null;
  setUser?: (user?: UserState | null) => void;
}

export const initialState: InitialState = {
  user: undefined,
  setUser: () => {}
};

export default React.createContext(initialState);
