import React from "react";

interface Props {
  active: boolean;
  payload: [{ value: string }];
  label: string;
}

const CustomTooltip: React.FC<Props> = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="p-2 text-xs rounded opacity-75 bg-gray-800">
        <p className="text-white">Created at: {label}</p>
        <p className="text-white">Count: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
