import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteProps,
  Switch
} from "react-router-dom";
import UserContext, { UserState } from "./contexts/user";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import ManageLayout from "./layouts/Manage";
import DashboardsList from "./pages/dashboards/List";
import ManageDashboard from "./pages/dashboards/Manage";
import ViewDashboard from "./pages/dashboards/View";
import DataSourcesList from "./pages/dataSources/List";
import LoadingPage from "./pages/Loading";
import Login from "./pages/Login";
import Account from "./pages/settings/Account";
import Billing from "./pages/settings/Billing";
import SlackSettings from "./pages/settings/Slack";
import Signup from "./pages/Signup";

const App: React.FC = () => {
  const [user, setUser] = useState<UserState | null>();
  const { loading, data } = useQuery(ME);

  useEffect(() => {
    if (!loading) {
      if (data && data.me) {
        return setUser(data.me);
      }

      return setUser(null);
    }
  }, [data, loading]);
  // user === undefined if loading
  // user === null if authentication failed
  // user === User if authed
  if (user === undefined) return <LoadingPage />;

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser }}>
        <Switch>
          <AuthRoute exact path="/" component={Login} />
          <AuthRoute exact path="/login" component={Login} />
          <AuthRoute exact path="/signup" component={Signup} />
          <RouteWithLayout
            exact
            path="/dashboards/:id"
            layout={DashboardLayout}
            component={ViewDashboard}
          />
          <RouteWithLayout
            exact
            path="/settings/account"
            layout={ManageLayout}
            component={Account}
          />
          <RouteWithLayout
            exact
            path="/settings/billing"
            layout={ManageLayout}
            component={Billing}
          />
          <RouteWithLayout
            exact
            path="/settings/slack"
            layout={ManageLayout}
            component={SlackSettings}
          />
          <RouteWithLayout
            exact
            layout={ManageLayout}
            path="/settings/dashboards/new"
            component={DashboardsList}
          />
          <RouteWithLayout
            layout={ManageLayout}
            path="/settings/dashboards/:id"
            component={ManageDashboard}
          />
          <RouteWithLayout
            path="/settings/dashboards"
            layout={ManageLayout}
            component={DashboardsList}
          />
          <RouteWithLayout
            path="/settings/data-sources"
            layout={ManageLayout}
            component={DataSourcesList}
          />
          <RouteWithLayout
            path="/settings/data-sources/:id/edit"
            layout={ManageLayout}
            component={DataSourcesList}
          />
          <RouteWithLayout
            path="/settings/data-sources/:id/edit-table"
            layout={ManageLayout}
            component={DataSourcesList}
          />
        </Switch>
      </UserContext.Provider>
    </Router>
  );
};

interface ComponentProps {
  component: React.FC;
}

type ComponentRouteProps = RouteProps & ComponentProps;

const AuthRoute: React.FC<ComponentRouteProps> = ({
  component: Component,
  children,
  ...props
}) => (
  <AuthLayout>
    <Route {...props}>
      <Component />
    </Route>
  </AuthLayout>
);

interface RouteWithLayoutProps {
  component: React.FC;
  layout: React.FC;
}

type RouteWithLayoutComponentProps = RouteProps & RouteWithLayoutProps;

const RouteWithLayout: React.FC<RouteWithLayoutComponentProps> = ({
  component: Component,
  layout: Layout,
  ...props
}) => (
  <Layout>
    <Route {...props}>
      <Component />
    </Route>
  </Layout>
);

const ME = gql`
  query {
    me {
      id
      name
      email
      projects {
        id
        name
        dashboards {
          id
          name
          description
        }
      }
    }
  }
`;

export default App;
