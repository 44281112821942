import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import { RichSelect } from "../../components/form-inputs";
import Notify from "../../components/notify";
import { DataSource } from "./Header";

const ChooseAccount: React.FC = () => {
  const { id } = useParams();
  const { data } = useQuery(DATASOURCE, {
    variables: { id }
  });
  const [update, { loading }] = useMutation(UPDATE_ACCOUNT, {
    refetchQueries: [{ query: DATASOURCE, variables: { id } }],
    onError: error => {
      Notify.graphQLError(error);
    }
  });

  if (data.dataSource === undefined) return null;
  const dataSource = data.dataSource;

  return (
    <>
      {dataSource.connectedAccounts && (
        <div>
          <RichSelect
            label="Select an account for this data source"
            isLoading={loading}
            disabled={dataSource.connectedAccount.id != null}
            placeholder="Please choose one"
            options={dataSource.connectedAccounts}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            inputProps={{
              value: dataSource.connectedAccount,
              onChange: ({ target: { id, name } }: { target: DataSource }) => {
                update({
                  variables: {
                    input: {
                      id: dataSource.id,
                      connectedAccount: {
                        id,
                        name
                      }
                    }
                  }
                });
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default ChooseAccount;

const UPDATE_ACCOUNT = gql`
  mutation updateDataSourceAccount($input: UpdateDataSourceAccountInput!) {
    updateDataSourceAccount(input: $input) {
      id
      status
      connectedAccount {
        id
        name
      }
    }
  }
`;

const DATASOURCE = gql`
  query dataSource($id: ID!) {
    dataSource(id: $id) {
      id
      connectedAccount {
        id
        name
      }
      connectedAccounts {
        id
        name
      }
    }
  }
`;
