import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import Spinner from "react-svg-spinner";
import MetricTableColumns from "./MetricTableColumns";
interface Props {
  metricTable: {
    id: string;
    name: string;
    labelsColumn: string;
    valuesColumn: string;
  };
  table: any;
}

const MetricTable: React.FC<Props> = ({ table, metricTable }) => {
  const { id } = useParams();
  const [create, { loading: creating }] = useMutation(CREATE_METRIC_TABLE, {
    update(cache, { data: { createMetricTable } }) {
      const cachedData: any = cache.readQuery({
        query: METRIC_TABLES,
        variables: { dataSourceId: id }
      });
      cache.writeQuery({
        query: METRIC_TABLES,
        variables: { dataSourceId: id },
        data: {
          metricTables: cachedData.metricTables.concat([createMetricTable])
        }
      });
    }
  });
  const [destroy, { loading: destroying }] = useMutation(DELETE_METRIC_TABLE, {
    update(cache, { data: { deleteMetricTable } }) {
      const cachedData: any = cache.readQuery({
        query: METRIC_TABLES,
        variables: { dataSourceId: id }
      });
      cache.writeQuery({
        query: METRIC_TABLES,
        variables: { dataSourceId: id },
        data: {
          metricTables: cachedData.metricTables.filter(
            (metricTable: any) => metricTable.id !== deleteMetricTable.id
          )
        }
      });
    }
  });
  const toggleChecked = () => {
    if (metricTable == null) {
      create({
        variables: { input: { dataSourceId: id, name: table.title } }
      });
    } else {
      destroy({
        variables: { input: { id: metricTable.id } }
      });
    }
  };

  return (
    <tr className="w-full odd:bg-gray-200">
      <td className="p-1">
        {(creating || destroying) && (
          <Spinner color="#377DFF" size="16px" thickness={2} gap={1} />
        )}
        {!creating && !destroying && (
          <input
            onChange={toggleChecked}
            checked={metricTable != null}
            type="checkbox"
          />
        )}
      </td>
      <td onClick={toggleChecked} className="cursor-pointer py-1">
        {table.title}
      </td>
      {metricTable == null && <BlankColumns />}
      {metricTable != null && (
        <MetricTableColumns metricTable={metricTable} sheetName={table.title} />
      )}
    </tr>
  );
};

const BlankColumns = () => (
  <>
    <td></td>
    <td></td>
  </>
);

export default MetricTable;

const METRIC_TABLES = gql`
  query metricTables($dataSourceId: ID!) {
    metricTables(dataSourceId: $dataSourceId) {
      id
      name
      labelsColumn
      valuesColumn
    }
  }
`;

const CREATE_METRIC_TABLE = gql`
  mutation createMetricTable($input: CreateMetricTableInput!) {
    createMetricTable(input: $input) {
      id
      name
      labelsColumn
      valuesColumn
    }
  }
`;

const DELETE_METRIC_TABLE = gql`
  mutation deleteMetricTable($input: DeleteInput!) {
    deleteMetricTable(input: $input) {
      id
    }
  }
`;
