import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

interface Props {
  isOpen: boolean;
  children: React.ReactChild;
  size: string;
  onHide: () => void;
}

const Modal: React.FC<Props> = ({ isOpen, children, size, onHide }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded) return null;
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex justify-center items-start">
      <button
        type="button"
        className="cursor-default w-screen h-screen z-10 absolute opacity-50 inset-0 bg-gray-500"
        onClick={onHide}
      />
      <div
        className={`w-full mt-40 z-20 ${size} bg-white rounded p-4 shadow-md`}
      >
        {children}
      </div>
    </div>,
    document.getElementsByTagName("body")[0]
  );
};

export default Modal;
