import React from "react";
import { SettingOutline } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";

AntdIcon.add(SettingOutline);

const EditButton = () => (
  <AntdIcon className="cursor-pointer" type={SettingOutline} />
);

export default EditButton;
