import numeral from "numeral";
import React from "react";

export interface Props {
  value: string;
}

const SmallNumber: React.FC<Props> = ({ value }) => (
  <div className="mb-3 flex items-center justify-between">
    <div className="leading-tight font-semibold text-2xl text-gray-900">
      {numeral(value).format("0,0[.]0a")}
    </div>
  </div>
);

export default SmallNumber;
