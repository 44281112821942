import numeral from "numeral";
import React from "react";

numeral.nullFormat("-");

interface Props {
  value: string;
}

const Number: React.FC<Props> = ({ value }) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="leading-tight font-semibold text-5xl text-gray-900">
          {numeral(value).format("0,0[.]0a")}
        </div>
      </div>
    </div>
  );
};

export default Number;
