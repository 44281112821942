import React from "react";

const Footer = () => (
  <div className="flex flex-wrap justify-center sm:justify-between">
    <div className="text-sm text-gray-600 mb-5 md:mb-0">
      Copyright © 2019 <a href="https://www.metricsjar.com">MetricsJar</a>{" "}
      &#183; Made in Sydney, Australia.
    </div>
  </div>
);

export default Footer;
