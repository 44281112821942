import React from "react";
import Spinner from "react-svg-spinner";

const LoadingPage = () => (
  <div className="w-full flex justify-center pt-12">
    <Spinner color="#cbd5e0" size="30px" speed="slow" thickness={2} gap={2} />
  </div>
);

export default LoadingPage;
