import React from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useAuthed } from "../hooks/use-authed";
import Head from "../components/head";
import { Input, SubmitBtn } from "../components/form-inputs";
import GraphQLError from "../components/graphql-error";
import { useForm } from "../hooks/use-form";

const SIGNUP = gql`
  mutation($input: SignupInput!) {
    signup(input: $input) {
      jwt
      user {
        id
        name
        email
        projects {
          id
          name
          dashboards {
            id
          }
        }
      }
    }
  }
`;

const Signup = () => {
  const [onAuthed] = useAuthed();
  const [signup, { loading, error }] = useMutation(SIGNUP, {
    onCompleted: data => {
      onAuthed(data.signup);
    }
  });
  const { fieldProps, currentValues } = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      projectName: ""
    }
  });

  return (
    <>
      <Head title="Sign up" />
      <p className="text-gray-600">Sign up for an account</p>
      <form
        onSubmit={e => {
          e.preventDefault();
          signup({
            variables: {
              input: currentValues
            }
          });
        }}
        className="py-8"
      >
        <GraphQLError error={error} />
        <Input
          label="Full name"
          inputProps={{
            placeholder: "John Doe",
            type: "text",
            ...fieldProps("name")
          }}
        />
        <Input
          label="Email address"
          inputProps={{
            placeholder: "john@doe.com",
            type: "email",
            ...fieldProps("email")
          }}
        />
        <Input
          label="Company name"
          inputProps={{
            placeholder: "Acme Inc",
            type: "text",
            ...fieldProps("projectName")
          }}
        />
        <Input
          label="Password"
          inputProps={{
            placeholder: "Password (at least 6 characters long)",
            type: "password",
            ...fieldProps("password")
          }}
        />
        <SubmitBtn loading={loading}>Sign up</SubmitBtn>
        <Link
          className="ml-3 text-md inline-block py-2 px-4 rounded text-brand-blue"
          to="/login"
          href="/login"
        >
          Have an account?
        </Link>
      </form>
    </>
  );
};

export default Signup;
