import React from "react";
import { useHistory } from "react-router-dom";

const Done: React.FC = () => {
  const history = useHistory();
  return (
    <button
      onClick={() => {
        history.push("/settings/data-sources");
      }}
      className="inline-block bg-brand-blue py-1 px-3 text-white rounded"
    >
      Done
    </button>
  );
};

export default Done;
