import {
  ApiOutline,
  ArrowLeftOutline,
  ControlOutline,
  DownOutline,
  PoweroffOutline,
  SettingOutline,
  ToolOutline,
  UserOutline
} from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import { IconDefinition } from "@ant-design/icons/lib/types";
import { useApolloClient } from "@apollo/react-hooks";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../contexts/user";

AntdIcon.add(
  ApiOutline,
  SettingOutline,
  UserOutline,
  ArrowLeftOutline,
  DownOutline,
  ToolOutline,
  PoweroffOutline,
  ControlOutline
);

const AccountDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userContext = useContext(UserContext);
  const client = useApolloClient();
  const logout = async () => {
    Cookies.remove("jwt");
    userContext.setUser!(null);
    // Force a reload of all the current queries now that the user is
    // logged in, so we don't accidentally leave any state around.
    await client.cache.reset();
  };
  const { user } = userContext;
  const project = user!.projects[0];
  const dashboard = project.dashboards[0];

  return (
    <>
      <div className="z-20 relative inline-block">
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="flex items-center text-brand-blue rounded-lg px-3 py-2 focus:outline-none focus:shadow-outline hover:bg-gray-200"
        >
          <div className="text-xl">
            <AntdIcon className="block mr-2" type={ControlOutline} />
          </div>
          <div>{project.name}</div>
        </button>
        {isOpen && (
          <div>
            <div
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="fixed inset-0"
              tabIndex={-1}
            ></div>
            <div className="mt-2 absolute right-0 origin-top-right">
              <div className="w-64 text-left bg-white rounded-lg shadow-lg">
                <div className="flex items-center px-6 py-4">
                  <img
                    className="bg-gray-300 h-10 w-10 rounded-full flex-no-shrink"
                    src=""
                    alt=""
                  />
                  <div className="ml-4">
                    <p className="font-semibold text-gray-900 leading-none">
                      {user!.name}
                    </p>
                  </div>
                </div>
                <DropdownLink
                  label="Go to dashboard"
                  to={`/dashboards/${dashboard.id}`}
                  icon={ArrowLeftOutline}
                  onClick={() => setIsOpen(false)}
                />
                <DropdownLink
                  label="Manage dashboards"
                  to="/settings/dashboards"
                  icon={ToolOutline}
                  onClick={() => setIsOpen(false)}
                />
                <DropdownLink
                  label="Manage data sources"
                  to="/settings/data-sources"
                  icon={ApiOutline}
                  onClick={() => setIsOpen(false)}
                />
                <DropdownLink
                  label="Account settings"
                  to="/settings/account"
                  icon={SettingOutline}
                  onClick={() => setIsOpen(false)}
                />
                <div className="border-t-2 border-gray-200 py-1">
                  <button
                    onClick={async e => {
                      e.preventDefault();
                      await logout();
                    }}
                    className="block w-full px-6 py-3 text-left leading-tight hover:bg-gray-200"
                  >
                    <AntdIcon
                      className="inline-block mr-2"
                      type={PoweroffOutline}
                      primaryColor="#718096"
                    />
                    Sign out
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface DropdownLinkProps {
  onClick: any;
  label: string;
  to: string;
  icon: IconDefinition;
}

const DropdownLink: React.FC<DropdownLinkProps> = ({
  icon,
  label,
  to,
  onClick
}) => {
  return (
    <div className="border-t-2 border-gray-200 py-1">
      <NavLink
        onClick={onClick && onClick}
        to={to}
        activeStyle={{
          color: "#377DFF"
        }}
        className="block flex items-center px-6 py-3 leading-tight hover:bg-gray-200"
      >
        <AntdIcon className="block mr-2" type={icon} primaryColor="#718096" />
        <div>{label}</div>
      </NavLink>
    </div>
  );
};

export default AccountDropdown;
