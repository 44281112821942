import React from "react";

const data = [
  {
    name: "/",
    pv: 3403
  },
  {
    name: "/pages/blog",
    pv: 430
  },
  {
    name: "/blog/how-to-mvp",
    pv: 340
  },
  {
    name: "/resources/marketing",
    pv: 200
  },
  {
    name: "/signup",
    pv: 130
  },
  {
    name: "/login",
    pv: 86
  },
  {
    name: "/privacy-policy",
    pv: 5
  }
];

const Leaderboard = () => (
  <div className="mt-3 h-32 overflow-y-scroll">
    {data.map(row => (
      <div key={row.name} className="flex justify-between">
        <div className="text-xs text-gray-600">{row.name}</div>
        <div className="text-xs">{row.pv}</div>
      </div>
    ))}
  </div>
);

export default Leaderboard;
