import { ArrowLeftOutline } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import UserContext from "../contexts/user";
import AccountDropdown from "../layouts/AccountDropdown";
import LoggedIn from "../layouts/LoggedIn";

AntdIcon.add(ArrowLeftOutline);

const Settings: React.FC = ({ children }) => {
  const userContext = useContext(UserContext);
  const { user } = userContext;

  if (user == null) return null;
  const project = user!.projects[0];
  const dashboard = project.dashboards[0];

  return (
    <div className="w-11/12 py-6 mx-auto">
      <div className="w-full md:flex flex-wrap justify-center sm:justify-between mb-5">
        <div className="flex items-center">
          <Link to="/">
            <img alt="Logo" width="30" src="/LogoSquare.svg" />
          </Link>
          <div className="ml-5">
            <div className="text-xl font-semibold text-gray-900">
              {project.name}
            </div>
            {dashboard && (
              <div className="text-md">
                <Link
                  className="flex items-center text-brand-blue"
                  to={`/dashboards/${dashboard.id}`}
                >
                  <AntdIcon
                    className="mr-2"
                    type={ArrowLeftOutline}
                    primaryColor="#718096"
                  />
                  <div>Go to dashboard</div>
                </Link>
              </div>
            )}
          </div>
        </div>
        <AccountDropdown />
      </div>
      <div className="min-h-screen">{children}</div>
      <Footer />
    </div>
  );
};

const SettingsLoggedIn: React.FC = props => (
  <LoggedIn>
    <Settings {...props} />
  </LoggedIn>
);

export default SettingsLoggedIn;
