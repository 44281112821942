import React from "react";
import { MenuOutline } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";

AntdIcon.add(MenuOutline);

const ReorderButton = () => (
  <AntdIcon className="cursor-move" type={MenuOutline} />
);

export default ReorderButton;
