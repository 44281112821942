import React from "react";

export default class Icon extends React.Component {
  render() {
    return (
      <svg
        className="illustration mx-auto"
        width="500"
        viewBox="0 0 1155 700"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M358.457 246.608C356.89 240.128 359.788 233.391 365.571 230.072L583.745 104.87C592.374 99.9185 603.345 104.586 605.761 114.237L655.717 313.749C656.46 316.716 656.264 319.746 655.276 322.481L751.73 377.361C758.394 381.152 758.488 390.724 751.9 394.645L527.835 528.005C524.665 529.892 520.713 529.88 517.554 527.974L353.896 429.22C347.291 425.234 347.496 415.586 354.265 411.885L393.265 390.559L358.457 246.608Z"
          fill="#193170"
        />
        <g opacity="0.5">
          <path
            d="M517.554 527.974L353.897 429.22C347.291 425.234 347.496 415.586 354.265 411.884L582.992 286.815C586.035 285.151 589.721 285.183 592.735 286.897L751.73 377.361C758.394 381.152 758.488 390.724 751.9 394.645L527.835 528.005C524.665 529.892 520.713 529.88 517.554 527.974Z"
            fill="#969595"
          />
          <path
            d="M358.457 246.608C356.89 240.128 359.788 233.391 365.571 230.072L583.745 104.87C592.374 99.9185 603.345 104.586 605.761 114.237L655.717 313.748C657.329 320.188 654.518 326.921 648.805 330.301L429.957 459.799C421.279 464.934 410.108 460.216 407.738 450.415L358.457 246.608Z"
            fill="#C4C4C4"
          />
        </g>
        <path
          d="M378.472 253.387C376.877 246.87 379.805 240.086 385.641 236.776L574.008 129.925C582.619 125.04 593.518 129.691 595.949 139.289L638.852 308.658C640.493 315.135 637.652 321.916 631.885 325.289L442.943 435.802C434.281 440.869 423.184 436.166 420.799 426.419L378.472 253.387Z"
          fill="white"
        />
        <path
          d="M516 512.5C498.086 503.009 497.908 492.618 495.194 487.446C495.194 481.971 548.12 469.195 552.192 471.933V493.5L586 514.824C588 519.824 586.849 537.196 584.5 554.324C583.056 564.855 582.523 573.472 578 574.824C574.947 575.736 560.727 572.353 560.727 570.528C560.727 568.703 563.781 543.151 563.781 533.112C563.781 523.074 538.392 524.364 516 512.5Z"
          fill="#9CB9F1"
        />
        <path
          d="M602.5 488.5C613.5 494.5 614 509.5 604 515L562 541C562 541 556.808 540.5 554 537.5C551.192 534.5 549.5 527.5 549.5 527.5L574 507.5L546.5 494.5L552.192 470.933C556.263 473.67 593.179 483.416 602.5 488.5Z"
          fill="#A4C3FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M489.5 393.002C497.561 385.789 505.084 382.781 514.532 380.001C523.981 377.221 548.121 380.175 553.21 382C558.299 383.825 564.035 411.762 562 414.5C561.144 415.65 559.636 416.614 558.174 417.547C556.156 418.836 554.227 420.067 554.227 421.654C554.227 422.925 554.008 433.237 553.773 444.289L553.773 444.29L553.773 444.292C553.502 457.054 553.21 470.803 553.21 472.759C553.21 476.409 542.014 480.972 538.96 481.885C538.232 482.102 536.867 482.528 535.113 483.074C529.515 484.818 519.958 487.795 514.532 489.185C507.408 491.01 496.018 490.652 495 487.002C494.685 485.871 494.037 484.017 493.293 481.886C491.636 477.138 489.5 471.02 489.5 468.5L482 434.502C481.983 434.024 481.96 433.472 481.934 432.857C481.558 423.82 480.606 400.96 489.5 393.002ZM558 539.5C558 539.5 554 542.46 554 544.5C554 545.185 553.99 545.86 553.979 546.528C553.918 550.492 553.861 554.221 556 558.5C558.5 563.5 558.5 564.5 557 566C556.391 566.609 551.863 566.097 549.5 565C542.372 561.69 539 548.5 539 548.5C539 548.5 537 539 538 533.5C538.731 529.478 541.447 529.551 544.14 529.624C545.13 529.65 546.116 529.677 547 529.5C549.5 529 550.5 528 550.5 528C550.5 528 551.663 531.842 553 534C554.529 536.468 558 539.5 558 539.5ZM561.5 571C560 574.5 559.5 579.5 560 582.601C560.872 588.007 576.321 605.416 579.374 605.416C579.68 605.416 580.037 605.425 580.429 605.435L580.43 605.435C583.949 605.525 590.264 605.688 587.517 599.94C586.32 597.436 584.943 594.729 583.606 592.1C581.532 588.024 579.556 584.139 578.5 581.5C577.5 579 576.5 576 576.5 574C575.42 574 573.822 573.749 571.705 573.416L571.705 573.416C570.882 573.286 569.98 573.144 569 573C567.147 572.727 565.261 572.105 563.81 571.627C562.742 571.275 561.91 571 561.5 571ZM618.856 538.536C614.186 543.461 604.846 554.014 604.846 556.828C604.846 559.21 612.232 558.366 619.177 557.573C622.489 557.195 625.701 556.828 627.963 556.828L815 445.666C815 441.211 813.319 432.299 806.594 432.299C799.869 432.299 678.633 503.123 618.856 538.536Z"
          fill="#2357DD"
        />
        <path
          d="M555.245 522.039L524.711 500.137L563.388 477.322L587.816 493.749L555.245 522.039Z"
          fill="#6C7686"
        />
        <path
          d="M565.423 470.501C563.866 465.252 558.076 447.001 556 443C555.795 442.605 554.965 440.598 553.9 438.5L554 421.5C554 421.5 554.133 420.671 555.5 419.5C556.924 418.281 561.098 415.5 561.098 415.5C563.423 423.783 565.814 433.371 567.423 437.5C570.347 445 573.607 465.363 575.5 470.501C579 480.001 577.637 492.835 573.566 494.661C569.495 496.486 565.423 493.748 565.423 490.098C565.423 486.447 567.5 477.501 565.423 470.501Z"
          fill="#EDC7C5"
        />
        <path
          d="M587.815 493.748L555.245 522.038L603.083 501.048L616.314 482.797L587.815 493.748Z"
          fill="#4B5361"
        />
        <path
          d="M515.477 382.468C514.571 379.282 510.556 376.403 512.547 367.643C515.895 352.915 545.775 363.826 549.17 364.822C550.65 367.222 553.165 372.899 551.389 376.403C549.17 380.782 547.858 382.534 547.417 386.476C546.73 392.607 541.237 398.739 533.734 400.928C531.57 401.56 525.347 397.863 523.141 395.673C519.193 391.756 516.374 389.463 516.374 386.476C516.374 383.489 516.608 386.45 515.477 382.468Z"
          fill="#EDC7C5"
        />
        <path
          d="M513.375 357.798C515.248 355.148 522.791 350.473 525 349.5C527.208 347.554 541.396 349.028 542.5 350.001C543.604 350.975 546.815 354.044 549.024 355.991C551.232 357.937 553.441 362.803 553.441 364.75C553.441 366.696 556.754 366.696 557.859 369.616C558.963 372.536 553.441 373.509 546.815 375.456C540.189 377.402 535.043 372.961 532.625 372.961C524.75 372.961 518.625 377.726 518.625 377.726C518.625 377.726 516.789 371.461 514 374.501C511.211 377.54 516.211 381.01 515 382.001C512.584 383.977 509 372.536 509 369.616C509 366.696 510.312 362.13 513.375 357.798Z"
          fill="#4B5361"
        />
        <path
          d="M501 433C501 431.216 487.089 432.695 482 432.999C482 438.499 483.5 465.121 488 470.5C492.5 475.88 530.818 492.835 534.889 497.398C538.96 501.961 550.156 503.786 554.227 504.699C558.299 505.611 561.352 502.873 561.352 500.136C561.352 497.398 556.263 496.485 554.227 492.835C552.192 489.185 543.031 488.272 538.96 486.447C534.889 484.622 505 466.499 502.5 462.999C500 459.499 501 437.5 501 433Z"
          fill="#EDC7C5"
        />
        <path
          d="M434.5 392V307L484.5 364V208.5L534.5 252V208.5L555.5 229.5V163.5L597 208.5"
          stroke="#7D8CA6"
        />
        <circle cx="393" cy="253" r="4" fill="#C4C4C4" />
        <circle cx="406" cy="246" r="4" fill="#C4C4C4" />
        <circle cx="419" cy="239" r="4" fill="#C4C4C4" />
      </svg>
    );
  }
}
