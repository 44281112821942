import React, { useState } from "react";
import { useParams } from "react-router";
import { useForm } from "../../hooks/use-form";
import ListSpreadsheets from "./ListSpreadsheets";

const SearchSpreadsheets = () => {
  const { id } = useParams();
  const [query, setQuery] = useState(null);
  const { fieldProps } = useForm({
    initialValues: {
      name: ""
    }
  });

  return (
    <>
      <p className="text-gray-600">
        Search for a spreadsheet to sync from Google Sheets
      </p>
      <form
        className="mt-3"
        onSubmit={e => {
          e.preventDefault();
          setQuery(fieldProps("name").value);
        }}
      >
        <div className="flex">
          <div>
            <input
              type="search"
              placeholder="Type the spreadsheet name and press search"
              className="text-sm w-96 border border-l border-t border-b border-gray-300 bg-gray-100 p-1 rounded-l focus:outline-none focus:border-gray-500"
              value={fieldProps("name").value}
              onChange={fieldProps("name").onChange}
            />
          </div>
          <button className="text-white rounded-r border border-gray-300 bg-gray-200 text-gray-800 text-sm py-1 px-2">
            Search
          </button>
        </div>
        {query != null && <ListSpreadsheets dataSourceId={id!} name={query!} />}
      </form>
    </>
  );
};

export default SearchSpreadsheets;
