import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import UserContext from "../contexts/user";

const LoggedIn: React.FC = ({ children }) => {
  const { loading, data } = useQuery(ME);
  const userContext = useContext(UserContext);
  const { user } = userContext;

  if (!user) return <Redirect to="/login" />;
  return <>{children}</>;
};

const ME = gql`
  query {
    me {
      id
      name
      email
      projects {
        id
        name
        dashboards {
          id
          name
          description
        }
      }
    }
  }
`;

export default LoggedIn;
