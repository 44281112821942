import { CaretDownFill } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import UserContext from "../../contexts/user";

const SwitchDashboard: React.FC = () => {
  const { id } = useParams();
  const { loading, data } = useQuery(DASHBOARD, {
    variables: { id }
  });

  const [isSelecting, setIsSelecting] = useState(false);
  const userContext = useContext(UserContext);
  const { user } = userContext;

  if (loading) return <Loading />;
  const project = user!.projects[0];
  const dashboards = project.dashboards;
  const dashboard = data.dashboard;

  return (
    <>
      <button
        onClick={() => setIsSelecting(true)}
        className="hover:text-brand-blue text-left flex items-center focus:outline-none rounded"
      >
        <div>
          <div className="text-gray-900 text-xl font-semibold">
            {dashboard.name}
          </div>
          <div className="text-md -mt-1 text-gray-500">
            {dashboard.description}
          </div>
        </div>
        <div className="ml-4">
          <AntdIcon type={CaretDownFill} primaryColor="#718096" />
        </div>
      </button>
      <Modal
        size="max-w-xl"
        isOpen={isSelecting}
        onHide={() => setIsSelecting(false)}
      >
        <>
          <div className="text-xl font-light">Select a dashboard</div>
          <div>
            {dashboards.map(dashboard => (
              <Link
                key={dashboard.id}
                onClick={() => setIsSelecting(false)}
                className="mt-3 block p-3 border border-gray-200 rounded"
                to={`/dashboards/${dashboard.id}`}
              >
                <div className="text-xl font-semibold">{dashboard.name}</div>
                <div className="text-gray-600 -mt-1">
                  {dashboard.description}
                </div>
              </Link>
            ))}
          </div>
        </>
      </Modal>
    </>
  );
};

const DASHBOARD = gql`
  query dashboard($id: ID!) {
    dashboard(id: $id) {
      id
      name
      description
      dashboardMetrics {
        id
        type
        position
        name
        metricId
        dateRange
      }
    }
  }
`;

export default SwitchDashboard;
