import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Head from "../../components/head";
import AccountDropdown from "../../layouts/AccountDropdown";
import MetricValue from "../../pages/dashboards/MetricValue";
import SwitchDashboard from "./SwitchDashboard";

const ManageDashboard = () => {
  return (
    <>
      <div className="w-full md:flex flex-wrap justify-center sm:justify-between mb-5">
        <div className="flex items-center">
          <Link to="/">
            <img alt="Logo" width="30" src="/LogoSquare.svg" />
          </Link>
          <div className="ml-5">
            <SwitchDashboard />
          </div>
        </div>
        <div className="mb-10 md:mb-0">
          <AccountDropdown />
        </div>
      </div>
      <Dashboard />
    </>
  );
};

const Dashboard: React.FC = () => {
  const { id } = useParams();
  const { loading, data } = useQuery(DASHBOARD, {
    variables: { id }
  });

  if (loading) return <div className="text-gray-600">Loading dashboard...</div>;
  const dashboard = data.dashboard;

  return (
    <>
      <Head title={dashboard.name} />
      <div className="text-sm">
        <div className="flex flex-wrap">
          {dashboard.dashboardMetrics.map((dashboardMetric: any) => (
            <MetricValue
              key={dashboardMetric.id}
              dashboardMetric={dashboardMetric}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const DASHBOARD = gql`
  query dashboard($id: ID!) {
    dashboard(id: $id) {
      id
      name
      description
      dashboardMetrics {
        id
        type
        position
        name
        metricId
        dateRange
      }
    }
  }
`;

export default ManageDashboard;
