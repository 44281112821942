import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { startCase } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import Notify from "../../components/notify";
import { allDataSources } from "../../pages/dataSources/List";
import { DataSource } from "./Header";

const ChooseMetrics: React.FC = () => {
  const { id } = useParams();
  const { data } = useQuery(DATASOURCE, {
    variables: { id }
  });
  const [add] = useMutation(ADD_METRIC, {
    refetchQueries: [{ query: DATASOURCE, variables: { id } }],
    onError: error => {
      Notify.graphQLError(error);
    }
  });

  const [remove] = useMutation(REMOVE_METRIC, {
    refetchQueries: [{ query: DATASOURCE, variables: { id } }],
    onError: error => {
      Notify.graphQLError(error);
    }
  });

  if (data.dataSource == null) return null;
  const dataSource: DataSource = data.dataSource;
  const defaultDataSource = allDataSources.find(
    ({ name }) => name === dataSource.name
  );

  return (
    <>
      <p className="text-gray-600">
        Select the metrics that you would like to sync from{" "}
        {defaultDataSource!.title}
      </p>
      <div className="mt-4">
        {dataSource.metrics.map(metric => (
          <MetricCheckbox
            key={metric.id}
            status={metric.status}
            label={startCase(metric.name)}
            onAdd={() => {
              add({
                variables: {
                  input: {
                    name: metric.name,
                    dataSourceId: id
                  }
                }
              });
            }}
            onRemove={() => {
              remove({
                variables: {
                  input: {
                    id: metric.id
                  }
                }
              });
            }}
          />
        ))}
      </div>
    </>
  );
};

export interface Metric {
  id?: string;
  name?: string;
  label: string;
  status: string;
}

interface MetricCheckBoxProps extends Metric {
  onAdd: () => void;
  onRemove: () => void;
}

const MetricCheckbox: React.FC<MetricCheckBoxProps> = ({
  label,
  status,
  onAdd,
  onRemove
}) => {
  const active = status === "active";
  return (
    <div>
      <label className="mt-1 flex items-center">
        <input
          onClick={active ? onRemove : onAdd}
          defaultChecked={active}
          type="checkbox"
        />
        <div className="ml-3">{label}</div>
      </label>
    </div>
  );
};

export default ChooseMetrics;

const DATASOURCE = gql`
  query dataSource($id: ID!) {
    dataSource(id: $id) {
      id
      name
      metrics {
        id
        name
        status
      }
    }
  }
`;

const ADD_METRIC = gql`
  mutation addMetric($input: AddMetricInput!) {
    addMetric(input: $input) {
      id
      name
      status
    }
  }
`;

const REMOVE_METRIC = gql`
  mutation removeMetric($input: RemoveMetricInput!) {
    removeMetric(input: $input) {
      id
      name
      status
    }
  }
`;
