import React from "react";
import Head from "../../components/head";
import PageHeader from "../../components/page-header";

const SlackBot = () => {
  return (
    <>
      <Head title="Slack bot" />
      <PageHeader>Slack bot</PageHeader>
      <div className="mt-5 rounded p-4 border border-gray-300 w-96">
        <div className="text-gray-600">
          Connect MetricsJar to your Slack account and access your dashboards
          via slash commands
        </div>
        <button className="mt-3 rounded bg-brand-blue text-white text-sm py-1 px-2">
          Connect to Slack
        </button>
      </div>
    </>
  );
};

export default SlackBot;
