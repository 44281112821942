import React from "react";
import ReactSelect from "react-select";
import { getOptionLabel, getOptionValue } from "react-select/src/builtins";

interface InputProps {
  inputProps?: any;
  label?: string;
  value?: any;
  onChange?: any;
  options?: [
    {
      label: string;
      value: string;
    }
  ];
}

export const Input: React.FC<InputProps> = ({ label, inputProps }) => {
  return (
    <div className="mb-5">
      {label && <label className="block mb-2">{label}</label>}
      <input
        className="w-full border border-gray-300 bg-gray-100 p-2 rounded focus:outline-none focus:border-gray-500"
        {...inputProps}
      />
    </div>
  );
};

export const Textarea: React.FC<InputProps> = ({ label, inputProps }) => {
  return (
    <div className="mb-5">
      <label className="block mb-2">{label}</label>
      <textarea
        className="w-full border border-gray-300 focus:border-gray-500 bg-gray-100 p-2 rounded focus:outline-none"
        {...inputProps}
      />
    </div>
  );
};

export const Select: React.FC<InputProps> = ({
  label,
  inputProps,
  options
}) => {
  return (
    <div className="mb-5">
      <label className="block mb-2">{label}</label>
      <div className="relative">
        <select
          className="block appearance-none w-full bg-gray-100 px-4 py-2 rounded focus:outline-none"
          {...inputProps}
        >
          {options &&
            options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

interface TRichSelect {
  getOptionLabel?: getOptionLabel<any> | undefined;
  getOptionValue?: getOptionValue<any> | undefined;
  label: string;
  placeholder: string;
  options: any;
  inputProps: InputProps;
  disabled?: boolean;
  isLoading?: boolean;
}

export const RichSelect = ({
  label,
  placeholder,
  getOptionLabel,
  getOptionValue,
  options,
  disabled,
  isLoading,
  inputProps
}: TRichSelect) => (
  <div className="mb-5">
    <label className="block mb-2">{label}</label>
    <ReactSelect
      isLoading={isLoading}
      isDisabled={disabled}
      createable
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#377DFF"
        }
      })}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={options}
      value={inputProps.value}
      onChange={data => {
        inputProps.onChange &&
          inputProps.onChange({
            target: data
          });
      }}
    />
  </div>
);

interface SubmitBtnProps {
  loading?: boolean;
  onClick?: () => void;
}

export const SubmitBtn: React.FC<SubmitBtnProps> = ({ loading, ...props }) => {
  const opacityClass = loading ? "opacity-75" : "opacity-100";
  const hoverClass = loading ? "" : "hover:bg-brand-blue";

  return (
    <button
      {...props}
      type="submit"
      disabled={loading}
      className={`mt-2 inline-block py-1 px-3 rounded bg-brand-blue text-white ${hoverClass} ${opacityClass}`}
    >
      {loading ? "Loading..." : props.children}
    </button>
  );
};

export const DeleteBtn: React.FC<SubmitBtnProps> = ({ loading, ...props }) => {
  const opacityClass = loading ? "opacity-75" : "opacity-100";

  return (
    <button
      {...props}
      type="submit"
      disabled={loading}
      className={`text-red-500 ${opacityClass}`}
    >
      {loading ? "Loading..." : props.children}
    </button>
  );
};
