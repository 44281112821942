import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  DeleteBtn,
  Input,
  SubmitBtn,
  Textarea
} from "../../components/form-inputs";
import GraphQLError from "../../components/graphql-error";
import Loading from "../../components/loading";
import Notify from "../../components/notify";
import UserContext from "../../contexts/user";
import { useForm } from "../../hooks/use-form";
import { DASHBOARD } from "./Manage";

const EditDashboard = () => {
  const history = useHistory();
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const project = user!.projects[0];

  const { loading, data: dashboardData } = useQuery(DASHBOARD, {
    variables: { id }
  });

  const refetchQueries = [
    { query: DASHBOARDS, variables: { projectId: project.id } },
    { query: ME }
  ];
  const [update, { loading: updating, error }] = useMutation(UPDATE_DASHBOARD, {
    refetchQueries,
    onCompleted: () => {
      Notify.saved();
      history.push(`/settings/dashboards/${id}`);
    }
  });
  const [destroy, { loading: destroying }] = useMutation(DELETE_DASHBOARD, {
    refetchQueries,
    onCompleted: () => {
      history.push("/settings/dashboards");
    }
  });

  if (loading) return <Loading />;
  const { dashboard } = dashboardData;

  return (
    <Form
      initialValues={{
        id: dashboard.id,
        position: dashboard.position,
        name: dashboard.name,
        description: dashboard.description
      }}
      error={error}
      update={update}
      destroy={destroy}
      updating={updating}
      destroying={destroying}
    />
  );
};

interface FormProps {
  initialValues: any;
  error: any;
  update: (variables: any) => void;
  updating: boolean;
  destroy: (variables: any) => void;
  destroying: boolean;
}
const Form: React.FC<FormProps> = ({
  initialValues,
  update,
  updating,
  destroy,
  destroying,
  error
}) => {
  const { fieldProps, currentValues } = useForm({
    initialValues
  });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        update({
          variables: {
            input: currentValues
          }
        });
      }}
    >
      <div className="text-xl mb-3">Edit dashboard</div>
      <GraphQLError error={error} />
      <Input
        label="Name"
        inputProps={{
          ...fieldProps("name"),
          autoFocus: true,
          type: "text"
        }}
      />
      <Textarea
        label="Description"
        inputProps={{
          ...fieldProps("description"),
          rows: 3
        }}
      />
      <div className="flex items-center justify-between">
        <SubmitBtn loading={updating}>Save</SubmitBtn>
        <DeleteBtn
          loading={destroying}
          onClick={() => {
            destroy({
              variables: {
                input: {
                  id: currentValues.id
                }
              }
            });
          }}
        >
          Delete
        </DeleteBtn>
      </div>
    </form>
  );
};

export default EditDashboard;

export const DASHBOARDS = gql`
  query projectDashboards($projectId: ID!) {
    projectDashboards(projectId: $projectId) {
      id
      position
      name
      description
    }
  }
`;

const UPDATE_DASHBOARD = gql`
  mutation updateDashboard($input: UpdateDashboardInput!) {
    updateDashboard(input: $input) {
      id
      name
      description
    }
  }
`;

const DELETE_DASHBOARD = gql`
  mutation deleteDashboard($input: DeleteInput!) {
    deleteDashboard(input: $input) {
      id
    }
  }
`;

const ME = gql`
  query {
    me {
      id
      projects {
        id
        dashboards {
          id
          name
          description
        }
      }
    }
  }
`;
