import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { get, orderBy } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import Loading from "../../components/loading";
import Leaderboard from "../metricValues/Leaderboard";
import Number from "../metricValues/Number";
import TinyBarChart from "../metricValues/TinyBarChart";
import TinyLineChart from "../metricValues/TinyLineChart";

interface Props {
  dashboardMetric: any;
}

const DashboardMetricValue: React.FC<Props> = ({ dashboardMetric }) => {
  const [filter, setFilter] = useState(dashboardMetric.dateRange || "total");

  const { loading, data } = useQuery(METRIC_VALUES, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        metricId: dashboardMetric.metricId,
        createdAt: moment()
          .startOf("hour")
          .toISOString()
      }
    }
  });

  const metricValues = data.metricValues;
  // Order desc so the latest value is first
  const metricValue = get(orderBy(metricValues, "createdAt", "desc"), "[0]");
  const aggregatedValue = metricValue ? metricValue.aggregated[filter] : null;
  const value = aggregatedValue || "-";
  const type = dashboardMetric.type;

  return (
    <div className="relative border border-gray-300 rounded flex flex-col justify-between p-3 w-72 h-64 block mr-2 mb-2">
      <div>
        <div className="truncate text-md text-gray-800">
          {dashboardMetric.name}
        </div>
        {!loading && (
          <div>
            {type === "number" && <Number value={value} />}
            {type === "lineChart" && <TinyLineChart value={value} />}
            {type === "barChart" && <TinyBarChart value={value} />}
            {type === "leaderboard" && <Leaderboard />}
          </div>
        )}
      </div>
      <div className="relative">
        <div>
          <select
            value={filter}
            onChange={e => setFilter(e.target.value)}
            className="text-xs text-gray-800 h-6 outline-none"
          >
            <option value="last7days">Last 7 days</option>
            <option value="last30days">Last 30 days</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="thisWeek">This week</option>
            <option value="lastWeek">Last week</option>
            <option value="thisMonth">This month</option>
            <option value="lastMonth">Last month</option>
            <option value="total">All time</option>
          </select>
        </div>
        <div className="absolute bottom-0 right-0">
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default DashboardMetricValue;

export const METRIC_VALUES = gql`
  query metricValues($input: MetricValuesFilterInput!) {
    metricValues(input: $input) {
      id
      createdAt
      aggregated {
        thisHour
        last7days
        yesterday
        today
        lastWeek
        thisWeek
        thisMonth
        lastMonth
        last30days
        total
      }
    }
  }
`;
