import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { startCase } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DeleteBtn,
  Input,
  RichSelect,
  SubmitBtn
} from "../../components/form-inputs";
import GraphQLError from "../../components/graphql-error";
import BlankIllustration from "../../components/illustrations/blank";
import Loading from "../../components/loading";
import UserContext from "../../contexts/user";
import { useForm } from "../../hooks/use-form";

interface SubmitProps {
  currentValues: Record<string, string>;
  setValue: () => void;
}

interface FormProps {
  title?: string;
  deleting?: boolean;
  onDelete?: () => void;
  submitLabel: string;
  onSubmit: (props: SubmitProps) => void;
  initialValues: Record<string, string | object>;
  submitting: boolean;
  error?: ApolloError;
}

const Form = ({
  title,
  submitLabel,
  onSubmit,
  onDelete,
  initialValues,
  submitting,
  deleting,
  error
}: FormProps) => {
  const { user } = useContext(UserContext);
  const project = user!.projects[0];
  const { loading, data } = useQuery(DATA_SOURCES, {
    variables: { projectId: project.id }
  });
  const { fieldProps, setValue, currentValues } = useForm({
    initialValues
  });
  const [dataSource, setDataSource] = useState<any>(null);
  const { projectDataSources } = data;

  useEffect(() => {
    if (projectDataSources != null) {
      const metricId = currentValues.metricId;
      const dataSource = projectDataSources.find((projectDataSource: any) => {
        const { metrics } = projectDataSource;
        const metric = metrics.find((metric: any) => {
          const { id } = metric;
          return metricId === id;
        });
        return metric;
      });
      const selectedDataSource = dataSource || projectDataSources[0];
      setDataSource(selectedDataSource);
    }
  }, [projectDataSources]);

  useEffect(() => {
    if (dataSource != null) {
      const metric = dataSource!.metrics.find(
        (metric: any) => metric.id === currentValues.metricId
      );
      if (metric == null) {
        setValue("metricId", null);
      }
    }
  }, [dataSource]);

  if (loading) return <Loading />;
  if (dataSource == null) return <Blank />;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit({ currentValues, setValue });
      }}
    >
      {title && <div className="text-xl mb-3">{title}</div>}
      <GraphQLError error={error} />
      <Input
        label="Name"
        inputProps={{
          ...fieldProps("name"),
          autoFocus: true,
          type: "text"
        }}
      />
      <RichSelect
        label="Data source"
        placeholder="Select a data source"
        options={projectDataSources}
        getOptionLabel={({ name }) => startCase(name)}
        getOptionValue={({ id }) => id}
        inputProps={{
          value: dataSource,
          onChange: (e: any) => {
            setDataSource(e.target);
          }
        }}
      />
      <RichSelect
        label="Metric"
        placeholder="Select a metric"
        options={dataSource.metrics}
        getOptionLabel={({ id, name }) => {
          const selected = dataSource.metrics.find(
            (metric: any) => metric.metricId === currentValues.metricId
          );
          if (selected) {
            return startCase(selected.name);
          }

          return startCase(name);
        }}
        getOptionValue={({ id }) => id}
        inputProps={{
          value: dataSource.metrics.find(
            (metric: any) => metric.id === currentValues.metricId
          ),
          onChange: (e: any) => {
            setValue("metricId", e.target.id);
          }
        }}
      />
      <RichSelect
        label="Visualisation type"
        placeholder="Select a type"
        options={visualisationTypes}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ value }) => value}
        inputProps={{
          value: visualisationTypes.find(
            type => currentValues.type === type.value
          ),
          onChange: (e: any) => {
            setValue("type", e.target.value);
          }
        }}
      />
      <RichSelect
        label="Default date range"
        placeholder="Select a date range"
        options={dateRanges}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ value }) => value}
        inputProps={{
          value: dateRanges.find(
            dateRange => currentValues.dateRange === dateRange.value
          ),
          onChange: (e: any) => {
            setValue("dateRange", e.target.value);
          }
        }}
      />
      <div className="flex items-center justify-between">
        <SubmitBtn loading={submitting}>{submitLabel}</SubmitBtn>
        {onDelete && (
          <div className="mt-2 py-0 px-2">
            <DeleteBtn
              loading={deleting}
              onClick={() => {
                onDelete();
              }}
            >
              Delete
            </DeleteBtn>
          </div>
        )}
      </div>
    </form>
  );
};

const Blank = () => (
  <div className="text-center">
    <BlankIllustration />
    <div className="text-xl">No datasources</div>
    <p className="text-gray-600">
      Please connect some data sources to continue
    </p>
    <Link
      className="inline-block mt-3 mb-8 text-white bg-brand-blue rounded py-2 px-4"
      to="/settings/data-sources"
    >
      + Connect data sources
    </Link>
  </div>
);

const visualisationTypes = [
  { label: "Number", value: "number" },
  { label: "Line chart", value: "lineChart" },
  { label: "Bar chart", value: "barChart" },
  { label: "Leaderboard", value: "leaderboard" }
];

const dateRanges = [
  { label: "Last 7 days", value: "last7days" },
  { label: "Last 30 days", value: "last30days" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "This week", value: "thisWeek" },
  { label: "Last week", value: "lastWeek" },
  { label: "This month", value: "thisMonth" },
  { label: "Last month", value: "lastMonth" },
  { label: "All time", value: "total" }
];

export default Form;

const DATA_SOURCES = gql`
  query projectDataSources($projectId: ID!) {
    projectDataSources(projectId: $projectId) {
      id
      name
      metrics {
        id
        name
        status
      }
    }
  }
`;
