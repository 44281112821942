import React from "react";
import Head from "../../components/head";
import PageHeader from "../../components/page-header";

const Billing = () => {
  return (
    <>
      <Head title="Billing" />
      <PageHeader>Billing</PageHeader>
    </>
  );
};

export default Billing;
