import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import Head from "../../components/head";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import PageHeader from "../../components/page-header";
import SettingButton from "../../components/setting-button";
import DashboardMetricsList from "../../pages/dashboardMetrics/List";
import Edit from "../../pages/dashboards/Edit";

const ManageDashboard = () => {
  const history = useHistory();
  const { id } = useParams();
  const { loading, data } = useQuery(DASHBOARD, {
    variables: { id }
  });

  if (loading) return <Loading />;
  const { dashboard } = data;

  return (
    <>
      <Head title={dashboard.name} />
      <div className="mb-3 flex">
        <Link
          to="/settings/dashboards"
          className="block cursor-pointer text-brand-blue"
        >
          Dashboards
        </Link>
        <div className="text-gray-600 w-4 text-center">/</div>
        <div className="text-gray-600">{dashboard.name}</div>
      </div>
      <PageHeader>
        <div className="flex items-center">
          <div className="mr-2">{dashboard.name}</div>
          <div className="text-base">
            <Link
              to={`/settings/dashboards/${dashboard.id}/edit`}
              className="text-brand-blue"
            >
              <SettingButton />
            </Link>
          </div>
        </div>
      </PageHeader>
      <div className="-mt-2 mb-5">
        <div className="text-gray-600 mr-3">{dashboard.description}</div>
      </div>
      <DashboardMetricsList />
      <Route
        path="/settings/dashboards/:id/edit"
        render={props => (
          <Modal
            size="max-w-xl"
            isOpen={true}
            onHide={() => {
              history.push(`/settings/dashboards/${dashboard.id}`);
            }}
          >
            <Edit />
          </Modal>
        )}
      />
    </>
  );
};

export default ManageDashboard;

export const DASHBOARD = gql`
  query dashboard($id: ID!) {
    dashboard(id: $id) {
      id
      position
      name
      description
      dashboardMetrics {
        id
        position
        type
        name
        dateRange
        metricId
      }
    }
  }
`;
