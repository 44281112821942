import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";

interface Props {
  sheetName: string;
  metricTable: {
    id: string;
    name: string;
    labelsColumn: string;
    valuesColumn: string;
  };
}

const MetricTableColumns: React.FC<Props> = ({ metricTable, sheetName }) => {
  const { id } = useParams();
  const { data, loading } = useQuery(LIST_TABLE_COLUMNS, {
    variables: {
      input: {
        sheetName,
        dataSourceId: id
      }
    }
  });
  const [update, { loading: updating }] = useMutation(UPDATE_METRIC_TABLE, {});

  if (loading) return <td colSpan={2}>Fetching columns...</td>;
  const { labelsColumn, valuesColumn } = metricTable;
  const columns = data.listGoogleSheetTableColumns;

  return (
    <>
      <td className="py-1">
        <select
          defaultValue={labelsColumn || ""}
          onChange={e => {
            update({
              variables: {
                input: {
                  id: metricTable.id,
                  labelsColumn: e.target.value
                }
              }
            });
          }}
          className="w-32"
        >
          <option value="" disabled>
            Select column
          </option>
          {columns.map((column: any) => (
            <option key={column.column} value={column.column}>
              {column.label}
            </option>
          ))}
        </select>
      </td>
      <td className="py-1">
        <select
          defaultValue={valuesColumn || ""}
          onChange={e => {
            update({
              variables: {
                input: {
                  id: metricTable.id,
                  valuesColumn: e.target.value
                }
              }
            });
          }}
          className="w-32"
        >
          <option value="" disabled>
            Select column
          </option>
          {columns.map((column: any) => (
            <option key={column.column} value={column.column}>
              {column.label}
            </option>
          ))}
        </select>
      </td>
    </>
  );
};

export default MetricTableColumns;

const UPDATE_METRIC_TABLE = gql`
  mutation updateMetricTable($input: UpdateMetricTableInput!) {
    updateMetricTable(input: $input) {
      id
      name
      labelsColumn
      valuesColumn
    }
  }
`;

const LIST_TABLE_COLUMNS = gql`
  query listGoogleSheetTableColumns($input: ListTableColumnsInput!) {
    listGoogleSheetTableColumns(input: $input) {
      label
      column
    }
  }
`;
