import React from "react";
import { ApolloError } from "apollo-client";

interface props {
  error?: ApolloError;
}

const GraphQLError: React.FC<props> = ({ error }) => {
  if (error == null) return null;

  const errors = error.graphQLErrors[0].extensions!.exception.errors;
  const errorKeys = Object.keys(errors);

  return (
    <div className="mb-5 bg-yellow-200 border border-yellow-400 text-yellow-800 py-2 px-3 rounded">
      {errorKeys.map((key, idx) => (
        <p className={idx + 1 < errorKeys.length ? "mb-2" : ""} key={key}>
          {errors[key]}
        </p>
      ))}
    </div>
  );
};

export default GraphQLError;
