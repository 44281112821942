import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import ChooseMetricTables from "./ChooseMetricTables";
import ConnectedSpreadsheet from "./ConnectedSpreadsheet";
import Disconnect from "./Disconnect";
import Done from "./Done";
import Header from "./Header";
import SearchSpreadsheets from "./SearchSpreadsheets";

interface Props extends React.FC {
  notify?: {
    hide: () => void;
  };
}

const EditDataSourceTable: Props = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(DATASOURCE, {
    variables: { id }
  });

  if (loading) return <Loading />;
  const dataSource = data.dataSource;

  return (
    <div>
      <Header />
      {dataSource.connectedAccount.id != null && (
        <ConnectedSpreadsheet dataSource={dataSource} />
      )}
      <div className="mt-3">
        {dataSource.connectedAccount.id != null && <ChooseMetricTables />}
        {dataSource.connectedAccount.id == null && <SearchSpreadsheets />}
      </div>
      <div className="mt-5 flex justify-between items-center">
        <Done />
        <Disconnect />
      </div>
    </div>
  );
};

export default EditDataSourceTable;

const DATASOURCE = gql`
  query dataSource($id: ID!) {
    dataSource(id: $id) {
      id
      name
      status
      connectedAccount {
        id
        name
      }
      connectedAccounts {
        id
        name
      }
    }
  }
`;
