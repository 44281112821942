import { SettingOutline } from "@ant-design/icons";
import AntdIcon from "@ant-design/icons-react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Cookies from "js-cookie";
import { get } from "lodash";
import React, { useContext, useState } from "react";
import NewWindow from "react-new-window";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import Head from "../../components/head";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import PageHeader from "../../components/page-header";
import UserContext from "../../contexts/user";
import Edit from "../../pages/dataSources/Edit";
import EditTable from "../../pages/dataSources/EditTable";
import { API_URL } from "../../utils/config";

AntdIcon.add(SettingOutline);

interface DataSource {
  name: string;
  id?: string;
  title?: string;
  caption?: string;
  logo?: string;
  authUrl?: string;
}

const DataSourcesList: React.FC = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const project = user!.projects[0];
  const [authUrl, setAuthUrl] = useState<string | null>(null);

  const { loading, refetch, data } = useQuery(DATA_SOURCES, {
    variables: { projectId: project.id }
  });

  if (loading || data === undefined) return <Loading />;
  const { projectDataSources } = data;

  return (
    <>
      <Head title="Data sources" />
      <PageHeader>Data sources</PageHeader>
      <div className="text-gray-600 -mt-3 mb-5">
        All connected data sources will be queried and cached every hour{" "}
        <span role="img" aria-label="smile">
          😊
        </span>
      </div>
      <div className="flex flex-wrap pb-12">
        {allDataSources.map((dataSource: DataSource) => {
          const projectDataSource = findProjectDataSource(
            projectDataSources,
            dataSource
          );
          const connected =
            get(projectDataSource, "status") === "connected" ||
            get(projectDataSource, "status") === "Select account" ||
            get(projectDataSource, "status") === "Select spreadsheet";
          return (
            <div
              key={dataSource.name}
              className="mr-2 mb-2 rounded p-4 border border-gray-300 w-96"
            >
              <img
                alt={`${dataSource.name} logo`}
                width="200"
                src={dataSource.logo}
              />
              <div className="text-gray-600 h-12">{dataSource.caption}</div>
              {connected ? (
                <ConnectedBtn
                  status={get(projectDataSource, "status")}
                  dataSource={projectDataSource}
                />
              ) : (
                <ConnectBtn
                  connecting={
                    dataSource.authUrl
                      ? dataSource.authUrl.includes(String(authUrl))
                      : false
                  }
                  authUrl={dataSource.authUrl}
                  onClick={() => {
                    setAuthUrl(
                      `${dataSource.authUrl}?provider=${
                        dataSource.name
                      }&projectId=${project.id}&jwt=${Cookies.get("jwt")}`
                    );
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <Switch>
        <Route
          path="/settings/data-sources/:id/edit"
          render={props => (
            <Modal
              size="max-w-xl"
              isOpen={true}
              onHide={() => {
                history.push("/settings/data-sources");
              }}
            >
              <Edit />
            </Modal>
          )}
        />
        <Route
          path="/settings/data-sources/:id/edit-table"
          render={props => (
            <Modal
              size="max-w-xl"
              isOpen={true}
              onHide={() => {
                history.push("/settings/data-sources");
              }}
            >
              <EditTable />
            </Modal>
          )}
        />
      </Switch>

      {authUrl && (
        <NewWindow
          url={authUrl}
          onUnload={() => {
            refetch();
            setAuthUrl(null);
          }}
        />
      )}
    </>
  );
};

export default DataSourcesList;

interface ConnectBtnProps {
  authUrl?: string;
  connecting: boolean;
  onClick: () => void;
}

const ConnectBtn: React.FC<ConnectBtnProps> = ({
  authUrl,
  connecting,
  onClick
}) => {
  if (authUrl == null)
    return (
      <button
        className="mt-3 bg-gray-200 border border-gray-300 text-gray-600 text-sm rounded py-1 px-2"
        disabled
      >
        Coming soon
      </button>
    );

  return (
    <button
      disabled={connecting}
      onClick={onClick}
      className={`mt-3 rounded bg-brand-blue text-white text-sm py-1 px-2 ${
        connecting ? "opacity-75" : "opacity-100"
      }`}
    >
      Connect
    </button>
  );
};

interface ConnectedBtnProps {
  status: string;
  dataSource: DataSource | null;
}

const ConnectedBtn: React.FC<ConnectedBtnProps> = ({ status, dataSource }) => {
  if (dataSource == null) return null;
  if (status === "Select spreadsheet")
    return (
      <Link
        to={`/settings/data-sources/${dataSource.id}/edit-table`}
        className="mt-3 inline-block rounded border bg-yellow-100 text-yellow-600 text-sm py-1 px-2"
      >
        Select spreadsheet
      </Link>
    );
  if (status === "Select account")
    return (
      <Link
        to={`/settings/data-sources/${dataSource.id}/edit`}
        className="mt-3 inline-block rounded border bg-yellow-100 text-yellow-600 text-sm py-1 px-2"
      >
        Select account
      </Link>
    );
  let editUrl = `/settings/data-sources/${dataSource.id}/edit`;
  if (dataSource.name === "googleSheets") {
    editUrl = `/settings/data-sources/${dataSource.id}/edit-table`;
  }
  return (
    <div className="flex items-center mt-3">
      <div className="inline-block rounded border bg-green-100 text-green-600 text-sm py-1 px-2">
        Connected
      </div>
      <Link to={editUrl} className="ml-5 text-brand-blue text-sm">
        <AntdIcon type={SettingOutline} primaryColor="#718096" />
      </Link>
    </div>
  );
};

const findProjectDataSource = (
  projectDataSources: DataSource[],
  dataSource: DataSource
) => {
  if (dataSource != null) {
    const projectDataSource = projectDataSources.find(
      ({ name }) => dataSource.name === name
    );
    if (projectDataSource != null) {
      return projectDataSource;
    }
  }

  return null;
};

export const DATA_SOURCES = gql`
  query projectDataSources($projectId: ID!) {
    projectDataSources(projectId: $projectId) {
      id
      name
      status
      metrics {
        id
        name
        status
      }
    }
  }
`;

export const allDataSources = [
  {
    name: "mailchimp",
    title: "MailChimp",
    caption: "Email opens, clickthroughs, and subscriber metrics",
    logo: "/FrameMailchimp.png",
    authUrl: `${API_URL}/auth/mailchimp`
  },
  {
    name: "twitter",
    title: "Twitter",
    caption: "Follower and mention counts",
    logo: "/FrameTW.png",
    authUrl: `${API_URL}/auth/twitter`
  },
  {
    name: "googleAnalytics",
    title: "Google Analytics",
    caption: "Web visits to your marketing sites and blogs",
    logo: "/FrameGA.png",
    authUrl: `${API_URL}/auth/google-analytics`
  },
  {
    name: "googleSheets",
    title: "Google Sheets",
    caption: "Any kinds of data stored within a spreadsheet",
    logo: "/FrameGoogleSheets.png",
    authUrl: `${API_URL}/auth/google-sheets`
  },
  {
    name: "postgres",
    title: "PostgreSQL",
    caption: "Connect to your database instance and create custom SQL queries",
    logo: "/FramePostgres.png"
  },
  {
    name: "profitwell",
    title: "ProfitWell",
    caption: "Recurring revenue, subscriptions, upgrades and churn",
    logo: "/FrameProfitWell.png"
  },
  {
    name: "intercom",
    title: "Intercom",
    caption: "Users, companies, segments, tags and support metrics",
    logo: "/FrameIntercom.png"
  },
  {
    name: "github",
    title: "GitHub",
    caption: "Commits, PR and deployment counts",
    logo: "/FrameGitHub.png"
  },
  {
    name: "stripe",
    title: "Stripe",
    caption: "Revenue, upgrades and cancellations",
    logo: "/FrameStripe.png"
  },
  {
    name: "convertkit",
    title: "ConvertKit",
    caption: "Subscriber metrics",
    logo: "/FrameConvertKit.png"
  },
  {
    name: "sendgrid",
    title: "Sendgrid",
    caption: "Email opens and clickthroughs",
    logo: "/FrameSendgrid.png"
  },
  {
    name: "chartmogul",
    title: "ChartMogul",
    caption: "Recurring revenue, subscriptions, upgrades and churn",
    logo: "/FrameChartMogul.png"
  },
  {
    name: "baremetrics",
    title: "Baremetrics",
    caption: "Recurring revenue, subscriptions, upgrades and churn",
    logo: "/FrameBaremetrics.png"
  },
  {
    name: "facebook",
    title: "Facebook",
    caption: "Likes and tagged counts",
    logo: "/FrameFB.png"
  },
  {
    name: "instagram",
    title: "Instagram",
    caption: "Follower and tagged counts",
    logo: "/FrameIG.png"
  }
];
