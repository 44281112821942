import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Input, SubmitBtn, Textarea } from "../../components/form-inputs";
import GraphQLError from "../../components/graphql-error";
import UserContext from "../../contexts/user";
import { useForm } from "../../hooks/use-form";
import { DASHBOARDS } from "../../pages/dashboards/List";

const NewDashboard = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const project = user!.projects[0];
  const { fieldProps, currentValues } = useForm({
    initialValues: {
      name: "",
      description: ""
    }
  });
  const { data } = useQuery(DASHBOARDS, {
    variables: { projectId: project.id }
  });
  const [create, { loading: creating, error }] = useMutation(CREATE_DASHBOARD, {
    refetchQueries: [
      { query: DASHBOARDS, variables: { projectId: project.id } },
      { query: ME }
    ],
    onCompleted: ({ createDashboard }) => {
      history.push(`/settings/dashboards/${createDashboard.id}`);
    }
  });

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          create({
            variables: {
              input: {
                projectId: project.id,
                position: data.projectDashboards.length,
                ...currentValues
              }
            }
          });
        }}
      >
        <div className="text-xl mb-3">Add a new dashboard</div>
        <GraphQLError error={error} />
        <Input
          label="Name"
          inputProps={{
            ...fieldProps("name"),
            autoFocus: true,
            type: "text"
          }}
        />
        <Textarea
          label="Description"
          inputProps={{
            ...fieldProps("description"),
            rows: 3
          }}
        />
        <div className="flex items-center justify-between">
          <SubmitBtn loading={creating}>Create</SubmitBtn>
        </div>
      </form>
    </>
  );
};

export default NewDashboard;

const CREATE_DASHBOARD = gql`
  mutation createDashboard($input: CreateDashboardInput!) {
    createDashboard(input: $input) {
      id
      name
    }
  }
`;

const ME = gql`
  query {
    me {
      id
      projects {
        id
        dashboards {
          id
          name
          description
        }
      }
    }
  }
`;
