import { get } from "lodash";
import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import UserContext from "../contexts/user";

const Auth: React.FC = ({ children }) => {
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const dashboardId = get(user, "projects[0]dashboards[0].id");

  if (user) {
    if (dashboardId) return <Redirect to={`/dashboards/${dashboardId}`} />;
    return <Redirect to={`/settings/dashboards`} />;
  }

  return (
    <div className="container w-3/4 md:w-1/3 xl:w-1/5 mx-auto my-16">
      <Link className="inline-block font-bold text-2xl" href="/" to="/">
        <img alt="Logo" width="200" src="/Logo.svg" />
      </Link>
      <div>{children}</div>
    </div>
  );
};

export default Auth;
