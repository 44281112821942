import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import CustomTooltip from "./CustomToolTip";
import SmallNumber, { Props } from "./SmallNumber";

const data = [
  {
    name: "2019-10-14",
    pv: 5
  },
  {
    name: "2019-10-15",
    pv: 86
  },
  {
    name: "2019-10-16",
    pv: 430
  },
  {
    name: "2019-10-17",
    pv: 340
  },
  {
    name: "2019-10-18",
    pv: 200
  },
  {
    name: "2019-10-19",
    pv: 3403
  },
  {
    name: "2019-10-20",
    pv: 130
  }
];

const TinyLineChart: React.FC<Props> = ({ value }) => {
  return (
    <>
      <SmallNumber value={value} />
      <LineChart
        width={260}
        height={130}
        data={data}
        margin={{
          left: -18,
          right: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={CustomTooltip} />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#377DFF"
          activeDot={{ r: 3 }}
        />
      </LineChart>
    </>
  );
};

export default TinyLineChart;
