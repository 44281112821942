import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useParams } from "react-router";
import Loading from "../../components/loading";
import { Metric } from "./ChooseMetrics";
import { allDataSources } from "./List";

const Header: React.FC = () => {
  const { id } = useParams();
  const { data } = useQuery(DATASOURCE, {
    variables: { id }
  });

  if (data === undefined) return <Loading />;

  const dataSource: DataSource = data.dataSource;
  if (dataSource == null) return <Loading />;

  const defaultDataSource = allDataSources.find(
    ({ name }) => name === dataSource.name
  );
  if (defaultDataSource == null) return <Loading />;
  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <img alt="Datasource logo" width="200" src={defaultDataSource.logo} />
        </div>
        {dataSource.status === "connected" && (
          <div className="inline-block rounded border bg-green-100 text-green-600 text-sm py-1 px-2">
            Connected
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

export interface DataSource {
  id: string;
  name: string;
  status: string;
  connectedAccount: any;
  connectedAccounts: any;
  metrics: Metric[];
}

export interface DataSource {
  id: string;
  name: string;
  status: string;
  connectedAccount: any;
  connectedAccounts: any;
  metrics: Metric[];
}

const DATASOURCE = gql`
  query dataSource($id: ID!) {
    dataSource(id: $id) {
      id
      name
      status
    }
  }
`;
