import React, { useState, useEffect } from "react";
import { orderBy, compact } from "lodash";
import { DocumentNode } from "graphql";
import { useMutation } from "@apollo/react-hooks";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

interface renderItemProps {
  item: any;
}

interface Props {
  data: {
    id: string;
    position: string;
  }[];
  renderItem: React.FC<renderItemProps>;
  updateMutation: DocumentNode;
  useDragHandle?: boolean;
}

interface SortArgs {
  oldIndex: number;
  newIndex: number;
}

const Sortable = ({
  data,
  renderItem,
  useDragHandle,
  updateMutation
}: Props) => {
  const [sortedIds, setSortedIds] = useState<string[]>([]);
  const [update] = useMutation(updateMutation);

  useEffect(() => {
    const ids = orderBy(data, "position").map(({ id }) => id);
    setSortedIds(ids);
  }, [data, data.length]);

  const items = compact(
    sortedIds.map(id => data.find(({ id: itemId }) => itemId === id))
  );

  return (
    <SortableList
      useDragHandle={useDragHandle}
      axis="xy"
      onSortEnd={({ oldIndex, newIndex }: SortArgs) => {
        const sorted = arrayMove(sortedIds, oldIndex, newIndex);
        items.forEach(({ id }) => {
          update({
            variables: {
              input: {
                id,
                position: sorted.indexOf(id)
              }
            }
          });
        });
        setSortedIds(sorted);
      }}
      collection={items}
      renderItem={renderItem}
    />
  );
};

const SortableItem = SortableElement((props: any) =>
  props.renderItem({ item: props.item })
);

const SortableList = SortableContainer((props: any) => {
  const { collection, renderItem } = props;
  return (
    <div className="flex flex-wrap">
      {collection.map((item: any, idx: number) => (
        <SortableItem
          key={idx}
          index={idx}
          item={item}
          renderItem={renderItem}
        />
      ))}
    </div>
  );
});

export default Sortable;
